import React from "react";
import styled from "styled-components";
import typography from "../helpers/styles/typography";
import colors from "../helpers/styles/colors";

const ActiveContainer = styled.div`
  border: 1px solid ${(props) => props.color};
  border-radius: 16px;
  padding: 2px 12px 2px 12px;
  color: ${(props) => props.color};
  max-width: 71px;
  max-height: 24px;
  text-align: center;
  margin: 10px 0px;
`;

const ActiveText = styled.p`
  font: ${typography.body};
  font-weight: 700;
`;

const ActiveBodyContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ActiveByBody = styled.p`
  font: ${typography.contentSmall};
  padding-bottom: 10px;
  color: ${colors.gray};
  padding: 2px 6px;
`;

export const ActiveIcon = ({ success }) => {
  return (
    <ActiveContainer color={`${success === true ? colors.successGreen : colors.gray}`}>
      <ActiveText>{success ? "Active" : "Inactive"}</ActiveText>
    </ActiveContainer>
  );
};

export const ActiveBody = ({ banner }) => {
  return (
    <ActiveBodyContainer>
      <ActiveIcon success={banner.active} />
      <ActiveByBody>{banner.activeFrom}</ActiveByBody>
    </ActiveBodyContainer>
  );
};
