import { css } from "styled-components";
import colors from "./colors";
import typography from "./typography";

export const LinkStyle = () => css`
  color: ${colors.linkBlue};
  font: ${typography.contentSmall};
  &:visited {
    color: ${colors.visitedLinkBlue};
  }
`;
