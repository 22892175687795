import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "styled-components";
import colors from "../helpers/styles/colors";
import breakpoints from "../helpers/styles/breakpoints";
import spacing from "../helpers/styles/spacing";

const StyledLink = styled(Link)`
  font-size: 18px;
  padding: 5px ${spacing.gutter30} 5px ${spacing.gutter20};
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  @media ${breakpoints.tablet} {
    font-size: 16px;
    padding: 11px ${spacing.gutter15};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:focus-visible {
    border: 2px solid white;
  }
`;

const ExternalLink = styled.a`
  font-size: 18px;
  padding: 5px ${spacing.gutter30} 5px ${spacing.gutter20};
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  @media ${breakpoints.tablet} {
    font-size: 16px;
    padding: 11px ${spacing.gutter15};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const NavigationItems = ({ data }) => {

  const menuItems = data.site.siteMetadata.menuLinks.map((menuItem) => {
    if (menuItem.external === true) {
      return (
        <li key={menuItem.name}>
          <ExternalLink
            href={menuItem.link}
            title={menuItem.name}
            aria-label={menuItem.name}
            tabIndex={0}
            activeStyle={{
              backgroundColor: `${colors.brandYellow}`,
              color: `${colors.black}`,
            }}
          >
            {menuItem.name}
          </ExternalLink>
        </li>
      );
    }

    return (
      <li key={menuItem.name}>
        <StyledLink
          to={menuItem.link}
          title={menuItem.name}
          aria-label={menuItem.name}
          tabIndex={0}
          partiallyActive={true}
          activeStyle={{
            backgroundColor: `${colors.brandYellow}`,
            color: `${colors.black}`,
          }}
        >
          {menuItem.name}
        </StyledLink>
      </li>
    );
  });

  return <>{menuItems}</>;
};

NavigationItems.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.object,
    }),
  }),
};

const NavigationItemsQuery = (props) => (
  <StaticQuery
    query={graphql`
      query SitePagesQuery {
        site {
          siteMetadata {
            menuLinks {
              link
              name
              external
              referenceId
            }
          }
        }
      }
    `}
    render={(data) => <NavigationItems data={data} {...props} />}
  />
);

export default NavigationItemsQuery;
