import { createGlobalStyle } from "styled-components";
import typography from "./typography";
import spacing from "./spacing";
import breakpoints from "./breakpoints";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    overflow-x: hidden;
    &.cookie-on-screen {
      .alert-banner {
        bottom: auto;
        top: 51px;
      }
    }
    &.cookie-accepted {
      .alert-banner {
        bottom: 0;
        top: auto;
      }
    }
  }

  p {
    font: ${typography.body};
    margin: 0;
    @media ${breakpoints.tablet} {
      font: ${typography.bodyDesktop}
    }
  }

  h1, h2, h3, h4, h5 {
    margin: ${spacing.gutter15} 0;
  }

  h3 {
    font: ${typography.h3};
    @media ${breakpoints.tablet} {
      font: ${typography.h3Desktop};
    }
  }

  .subtitle {
    font: ${typography.subtitleBold};
    margin-bottom: ${spacing.gutter15};
    @media ${breakpoints.tablet} {
      font: ${typography.subtitleBoldDesktop};
    }
  }

  .subtitle-small {
    font: ${typography.subtitleBold};
  }

  .positionR {
      position: relative;
  }

  .positionA {
      position: absolute;
  }
  
  .full-width {
      width: 100%;
  }
  
  #genesys-mxg-frame {
    z-index:998;
    bottom: 0px;
    right: 0;
  }

`;

export default GlobalStyle;
