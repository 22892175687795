import React, { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
const CookieBar = () => {
  useEffect(() => {
    if (document.cookie === "boostcookie=true") {
      document.body.classList.remove("cookie-on-screen");
    }
    if (document.cookie === "") {
      document.body.classList.add("cookie-on-screen");
    }
  });
  const addClassToBody = () => {
    document.body.classList.add("cookie-accepted");
  };
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept and Close"
      cookieName="boostcookiebar"
      contentClasses="cookie-content"
      onAccept={() => addClassToBody()}
      style={{
        background: "#fff",
        color: "#000",
        fontSize: "16px",
        borderTop: "1px solid #777",
        padding: "20px 15px",
        margin: "0 auto",
        width: "calc(100% - 30px)",
        alignItems: "flex-end",
        zIndex: "998",
        display: "block !important"
      }}
      contentStyle={{
        maxWidth: "900px",
        margin: "0 auto",
        paddingRight: "15px",
      }}
      buttonStyle={{
        fontSize: "16px",
        background: "transparent",
        display: "block",
        margin: "0 auto",
        padding: "20px 0 0 0",
        textAlign: "right",
        fontFamily: "Montserrat",
        fontWeight: "bold",
        color: "#107896"
      }}
      expires={150}
    >
      Like most websites Boost uses cookies to ensure that we give you the best experience on our
      website. If you continue without changing your settings, we'll assume that you are happy to
      receive all cookies on this site.
    </CookieConsent>
  );
};
export default CookieBar;