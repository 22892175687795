const spacing = {
  gutter10: "10px",
  gutter15: "15px",
  gutter20: "20px",
  gutter25: "25px",
  gutter30: "30px",
  gutter40: "40px",
  gutter50: "50px",
  gutter80: "80px",
};

export default spacing;
