const typography = {
  h1: "normal normal normal 48px/1.2 Baloo",
  h2: "normal normal 700 26px/2.25 Montserrat, sans-serif",
  h3: "normal normal 800 19px/1.47 Montserrat, sans-serif",
  h3Desktop: "normal normal 600 20px/1.75 Montserrat, sans-serif",
  h4: "normal normal 800 18px/1.56 Montserrat, sans-serif",
  h5: "Montserrat, sans-serif",
  h6: "Montserrat, sans-serif",
  headlineMobile: "normal normal 400 34px/1.3 Baloo",
  subHeader: "normal normal 300 22px/2 Montserrat, sans-serif",
  subtitle: "normal normal 500 16px/1.75 Montserrat, sans-serif",
  subtitleDesktop: "normal normal 300 20px/1.65 Montserrat, sans-serif",
  subtitleBold: "normal normal 800 16px/1.75 Montserrat, sans-serif",
  subtitleNormal: "normal normal normal 16px/1.75 Montserrat, sans-serif",
  subtitleBoldDesktop: "normal normal 800 24px/1.75 Montserrat, sans-serif",
  body: "normal normal normal 14px/1.71 Montserrat, sans-serif",
  bodyDesktop: "normal normal normal 16px/1.75 Montserrat, sans-serif",
  boldCaption: "normal normal 700 14px/1.25 Montserrat, sans-serif",
  contentSmall: "normal normal 400 16px/1.375 Montserrat, sans-serif",
  buttonContent: "normal normal 700 16px/1.375 Montserrat, sans-serif",
  fontSizeSmall: "12px",
  fontSizeRegular: "14px",
  fontSizeHS: "normal normal 500 20px/normal Montserrat, sans-serif",
  fontSizeHM: "24px",
  fontFamilyDisplay: "Baloo, cursive",
  fontFamilyText: "Montserrat, sans-serif",
};

export default typography;
