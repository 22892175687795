import React, { useEffect, useState } from "react";
import queryString from "query-string";
import styled from "styled-components";
import { keyframes } from "styled-components";
import colors from "../helpers/styles/colors";
import Cookies from "js-cookie";
import breakpoints from "../helpers/styles/breakpoints";
import { isWithinChatOpeningHours } from "../helpers/open-chat";

const isBrowser = typeof window !== "undefined";

const speechUpBigDevice = keyframes`
  0% {
    bottom: 0%;
    right: 0%;
    opacity: 0;
  }
  100% {
    bottom: 10%;
    right: 3%;
    opacity: 1;
    
  }
`;

const speechUpSmallDevice = keyframes`
  0% {
    bottom: 0%;
    right: 0%;
    opacity: 0;
  }
  100% {
    bottom: 10%;
    right: 5%;
    opacity: 1;
  }
`;

const speechUpMobileDevice = keyframes`
  0% {
    bottom: 0%;
    right: 0%;
    opacity: 0;
  }
  100% {
    bottom: 9%;
    right: 5%;
    opacity: 1;
  }
`;

const SpeechBubble = styled.div`
  position: fixed;
  background-color: ${colors.smokyWhite};
  color: ${colors.dustyBlue};
  padding: 5px 10px 5px 10px;
  z-index: 3;
  border: 0.5px solid ${colors.dustyBlue};
  border-radius: 15px;
  border-bottom-right-radius: 0;
  width: 25%;
  animation-name: ${speechUpSmallDevice};
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.09, 0.84, 0.36, 1.24);
  animtaion-iteration-count: 1;
  animation-fill-mode: forwards;

  @media ${breakpoints.mobileXL} {
    animation-name: ${speechUpBigDevice};
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.09, 0.84, 0.36, 1.24);
    animtaion-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @media ${breakpoints.maxWidth600px} {
    animation-name: ${speechUpMobileDevice};
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.09, 0.84, 0.41, 1.4);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 9999;
  }
`;

const CloseMe = styled.button`
  position: absolute;
  top: -3%;
  left: -3%;
  background-color: ${colors.dustyBlue};
  border-radius: 10px;
  color: white;
  height: 20px;
  widht: 20px;
  border: 0.5px solid ${colors.dustyBlue};
  box-shadow: 0px 0px #fff;
`;

const WebChatPromotion = () => {
  const [bubbleClosed, setBubbleClosed] = useState(true);

  const { chatOpen } = isBrowser && queryString.parse(window.location.search);

  useEffect(() => {
    const setupPromoVisibilityHandler = () => {
      if (isWithinChatOpeningHours()) {
        if (Cookies.get("bubble-closed")) {
          setBubbleClosed(true);
        } else if (document.querySelectorAll(".cookie-content").length) {
          setTimeout(() => {
            setupPromoVisibilityHandler();
          }, 1000);
        } else if (chatOpen === "true") {
          window.genesysOpen();
          setBubbleClosed(true);
        } else {
          setBubbleClosed(false);
          setInterval(() => {
            closeBubbleAndUpdateCookie();
          }, 10000);
        }
      }
    };
    window.addEventListener("onGenesysLauncherReady", setupPromoVisibilityHandler);
    return () => window.removeEventListener("onGenesysLauncherReady", setupPromoVisibilityHandler);
  }, [chatOpen]);

  function closeBubbleAndUpdateCookie() {
    setBubbleClosed(true);
    Cookies.set("bubble-closed", "true", { expires: 1 });
  }

  function closeBubbleOnClickHandler(e) {
    e.stopPropagation();
    closeBubbleAndUpdateCookie();
  }

  function speechBubbleOnClickHandler() {
    window.genesysOpen();
    closeBubbleAndUpdateCookie();
  }

  return (
    <>
      {!bubbleClosed && (
        <SpeechBubble onClick={speechBubbleOnClickHandler}>
          <h4>Hi there. Click here if you want to chat…</h4>
          <CloseMe onClick={(e) => closeBubbleOnClickHandler(e)}>X</CloseMe>
        </SpeechBubble>
      )}
    </>
  );
};

export default WebChatPromotion;
