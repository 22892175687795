import React, { useState } from "react";
import { DesktopHeader, MobileHeader } from "@ovotech/flash";
import styled from "styled-components";

import NavigationItems from "./NavigationItems";
import colors from "../helpers/styles/colors";
import typography from "../helpers/styles/typography";
import spacing from "../helpers/styles/spacing";
import CloseBtn from "../images/cross.svg";
import breakpoints from "../helpers/styles/breakpoints";

const headerHeight = "50px";

const StyledDesktopHeader = styled(DesktopHeader)`
  a:hover {
    letter-spacing: 0px !important;
  }
`;

const StyledMobileHeader = styled(MobileHeader)``;

const SkipToContent = styled.div`
  a {
    width: 26em;
    display: block;
    color: #fff;
    background: ${colors.black};
    padding: 5px;
    position: absolute;
    left: -1000em;
    top: 0;
  }
  a:focus {
    z-index: 200;
    top: 50px;
    left: 25px;
  }
`;

const NavigationContainer = styled.nav`
  display: flex;
  height: ${headerHeight};
  ${StyledDesktopHeader}, ${StyledMobileHeader} {
    width: calc(100% - 40px);
    position: fixed;
    z-index: 100;
    max-height: ${headerHeight};
  }

  ${StyledMobileHeader} {
    padding: 0 40px;
  }

  header {
    padding: 0 ${spacing.gutter25};
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    li {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }

    a {
      display: inline-flex;
      color: ${colors.white};
      text-decoration: none;
      font-family: ${typography.fontFamilyText};
      padding-top: 0;
      padding-bottom: 0;
      line-height: 50px;
    }
  }
`;

const Menu = styled.ul`
  display: inline-flex;
  float: right;
  overflow: auto;
  margin: 0;
  li {
    height: ${headerHeight};
    overflow: hidden;
  }
  a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  li:first-child {
    display: none;
  }
`;

const MenuMobile = styled.ul`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0px;
  margin: 0;
  height: 100vh;
  width: calc(100% - 3rem);
  max-width: 280px;
  z-index: 200;
  box-sizing: border-box;
  overflow-y: scroll;
  background-color: ${colors.black};
  transition: all 300ms;
  transform: ${(props) => (props.isOpen ? "translateX(0)" : "translateX(-100%)")};
  box-shadow: ${(props) => (props.isOpen ? "0 0 12px 0 #000000" : "0")};
  overflow: hidden;

  li:last-of-type {
    position: relative;
    margin-top: ${spacing.gutter20};
    &:before {
      content: "";
      position: absolute;
      width: calc(100% - 40px);
      right: 0;
      left: 0;
      top: 0;
      height: 1px;
      background-color: rgba(128, 128, 128, 0.42);
      margin: 0 auto;
    }
    a {
      padding-top: ${spacing.gutter10};
    }
  }
`;

const MobileLogo = styled.div`
  display: inline-block;
  font-family: Baloo, cursive;
  font-size: 30px;
  color: rgb(255, 212, 4);
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
  margin-left: ${spacing.gutter20};
  margin-bottom: ${spacing.gutter15};
`;

const ToggleMenuMobile = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 122;
  top: 0;
  left: 0;
  background-color: transparent;
  border: 0;
`;

const CloseButton = styled(({ isOpen, ...rest }) => <CloseBtn {...rest} />)`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  top: ${spacing.gutter20};
  right: ${spacing.gutter20};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  transform: none;
  transition: opacity 400ms ease 0s;
  opacity: ${(props) => (props.isOpen ? "0.99" : "0")};
  z-index: ${(props) => (props.isOpen ? "113" : "0")};

  left: ${(props) => (props.isOpen ? "0" : "-9999px")};
`;

const Desktop = styled.div`
  display: none;
  @media (min-width: ${breakpoints.sizeTablet}) {
    display: block;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: ${breakpoints.sizeTablet}) {
    display: block;
  }
`;

const Navigation = () => {
  const [isOpen, setisOpen] = useState(false);

  return (
    <>
      <SkipToContent>
        <a href="#main-content" tabIndex={0}>
          Skip to Content (Press Enter)
        </a>
      </SkipToContent>
      <NavigationContainer>
        <Desktop>
          <StyledDesktopHeader homeUrl="/">
            <Menu>
              <NavigationItems />
            </Menu>
          </StyledDesktopHeader>
        </Desktop>
        <Mobile>
          <StyledMobileHeader>
            <ToggleMenuMobile aria-label="Open menu" onClick={() => setisOpen(!isOpen)} />
          </StyledMobileHeader>
          <MenuMobile isOpen={isOpen}>
            <MobileLogo aria-label="Go to Boost homepage">boost</MobileLogo>
            <NavigationItems />
            <CloseButton
              aria-label="Close menu"
              onClick={() => setisOpen(!isOpen)}
              isOpen={isOpen}
            />
          </MenuMobile>
          <Overlay isOpen={isOpen} />
        </Mobile>
      </NavigationContainer>
    </>
  );
};

export default Navigation;
