const gray = "#979797";
const trueBlack = "#000000";
const white = "#FAFAFA";

const colors = {
  trueBlack,
  black: "#14130f",
  white,
  gray,
  successGreen: "#95CB46D9",
  smokyWhite: "#fafafa",
  trueWhite: "#ffffff",
  dustyBlue: "#107896",
  lightBlue: "#e9f5f9",
  dustyYellow: "#fec62d",
  brandYellow: "#FFD404",
  yellowGradient: "linear-gradient(to bottom, #fead2a, #fec62d)",
  lightBlueGradient: "linear-gradient(to bottom, #fafafa, #d2e6ec)",
  linkBlue: "#0E6680",
  visitedLinkBlue: "#593BAE",
  transparentGray: `${gray}2b`,
  transparentTrueBlack: `${trueBlack}99`,
  transparentWhite: `${white}99`,
  colonelMustard: "#ca4e19",
  lightGray: "#72716f",
  errorRed: "#D2341B",
  errorMuted: "#FEECE9",
  warningOrange: "#F78F1E",
  warningMuted: "#FEF4E9",
};

export default colors;
