import React from "react";
import styled from "styled-components";
import typography from "../helpers/styles/typography";
import breakpoints from "../helpers/styles/breakpoints";
import spacing from "../helpers/styles/spacing";
import { ActiveBody } from "./Active";
import { LinkStyle } from "../helpers/styles/LinkStyle";

const ParagraphContainer = styled.div`
  padding: 15px 0px 15px 0px;
  position: relative;
  text-align: left;
  padding-right: ${spacing.gutter25};

  @media ${breakpoints.tablet} {
    width: 100%;
    max-width: 600px;
    top: -25px;
  }
  @media ${breakpoints.maxMobileXL} {
    max-width: 450px;
  }
  h2 {
    font: ${typography.h3};
    @media ${breakpoints.tablet} {
      font: ${typography.h3Desktop};
    }
  }
  .subtitle {
    font-weight: normal;
    @media ${breakpoints.tablet} {
      font: ${typography.subtitleDesktop};
      margin: 0;
    }
  }
`;

const SectionTitle = styled.p`
  font: ${typography.bodyDesktop};
  font-weight: 500;
`;

const Body = styled.p`
  font: ${typography.contentSmall};
  padding-bottom: 10px;
`;

export const StyledLink = styled.a`
  text-decoration: underline;

  &hover {
    cursor: pointer;
  }
  ${LinkStyle()}
`;

export const Paragraph = (banner) => {
  return (
    <ParagraphContainer>
      <SectionTitle>{banner.subTitle}</SectionTitle>
      {banner.activeFrom && <ActiveBody {...{ banner }} />}
      <Body>{banner.body}</Body>
      <StyledLink href={banner.link}>{banner.linkTitle}</StyledLink>
    </ParagraphContainer>
  );
};
